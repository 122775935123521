const LOCAL_STORAGE_KEY = 'onboardingData';

export function getOnboardingData(): Record<number, { label: string; url: string }> {
    const data = localStorage.getItem(LOCAL_STORAGE_KEY);
    return data ? JSON.parse(data) : {};
}

export function saveOnboardingData(data: Record<number, { label: string; url: string }>) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
}
